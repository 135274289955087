import { useState, useEffect } from 'react';
import './NumSpeakersChooser.css';

function NumSpeakersChooser({ processor, minified, numSpeakers, setNumSpeakers }) {
    const [loading, setLoading] = useState(false);
    const [creatingEmbeddings, setCreatingEmbeddings] = useState(false);

    useEffect(() => {
        processor?.setNumSpeakers(numSpeakers);
    }, [numSpeakers]);

    useEffect(() => {
        const handleUpdateSpeakersStart = () => setLoading(true);
        const handleUpdateSpeakersEnd = () => setLoading(false);
        const handleCreateEmbeddingsStart = () => setCreatingEmbeddings(true);
        const handleCreateEmbeddingsEnd = () => setCreatingEmbeddings(false);

        processor?.on('updateSpeakersStart', handleUpdateSpeakersStart);
        processor?.on('updateSpeakersEnd', handleUpdateSpeakersEnd);

        processor?.on('createEmbeddingsStart', handleCreateEmbeddingsStart);
        processor?.on('createEmbeddingsEnd', handleCreateEmbeddingsEnd);

        return () => {
            processor?.off('updateSpeakersStart', handleUpdateSpeakersStart);
            processor?.off('updateSpeakersEnd', handleUpdateSpeakersEnd);

            processor?.off('createEmbeddingsStart', handleCreateEmbeddingsStart);
            processor?.off('createEmbeddingsEnd', handleCreateEmbeddingsEnd);
        };
    }, [processor]);

    return (
        <div className="num-speakers-chooser">
            {(creatingEmbeddings || loading) && <div className="loading-spinner"></div>}
            {!minified && <label className="title">Nº de veus:</label>}
            <input
                type="range"
                min={1}
                max={9}
                value={numSpeakers}
                onChange={(e) => setNumSpeakers(e.target.value)}
                className="num-speakers-slider"
            />
            <label className="value" style={{ fontSize: minified ? 14 : 16 }}>
                {numSpeakers} {minified ? 'veu' + (numSpeakers > 1 ? 's' : '') : ''}
            </label>
        </div>
    );
}

export default NumSpeakersChooser;