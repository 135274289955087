import localforage from "localforage";
import { readNextChunkFromIndexedDB, removeFileFromIndexedDB, saveAudioToIndexedDB, saveFileToIndexedDB } from "./indexedDB";
import { convertToWav, dataURLfromArrayBuffer } from "./chunking";
import { DecodingOptionsBuilder, Task, initialize } from "whisper-turbo";
import * as whisper from "whisper-webgpu";
import { fetchTokenizer } from "./models";
import EventEmitter from 'events';

const languageMap = {
    "ca": "catalan",
    "es": "spanish",
    "en": "english",
    "de": "german",
    "ar": "arabic",
    "hy": "armenian",
    "az": "azerbaijani",
    "eu": "basque",
    "be": "belarusian",
    "bn": "bengali",
    "bg": "bulgarian",
    "hr": "croatian",
    "ko": "korean",
    "da": "danish",
    "nl": "dutch",
    "sk": "slovak",
    "sl": "slovenian",
    "et": "estonian",
    "tl": "tagalog",
    "fi": "finnish",
    "fr": "french",
    "gl": "galician",
    "ka": "georgian",
    "cy": "welsh",
    "el": "greek",
    "gu": "gujarati",
    "iw": "hebrew",
    "hi": "hindi",
    "hu": "hungarian",
    "is": "icelandic",
    "id": "indonesian",
    "ga": "irish",
    "it": "italian",
    "ja": "japanese",
    "yi": "yiddish",
    "kn": "kannada",
    "la": "latin",
    "lv": "latvian",
    "lt": "lithuanian",
    "mk": "macedonian",
    "ms": "malay",
    "mt": "maltese",
    "no": "norwegian",
    "fa": "persian",
    "pl": "polish",
    "pt": "portuguese",
    "ro": "romanian",
    "ru": "russian",
    "sr": "serbian",
    "sw": "swahili",
    "sv": "swedish",
    "ta": "tamil",
    "te": "telugu",
    "th": "thai",
    "tr": "turkish",
    "cs": "czech",
    "uk": "ukrainian",
    "ur": "urdu",
    "vi": "vietnamese",
    "zh": "chinese"
};


export class WASMProcessor extends EventEmitter {
    constructor() {
        super();
        this.audioOffset = 0;
        this.instance = null;
        this.chunkData = null;
        this.bufferData = null;
        this.audio = null;
        this.language = 'ca';
        this.nthreads = navigator.hardwareConcurrency ?
            Math.max(1, Math.floor(navigator.hardwareConcurrency * 0.8)) :
            4;
        this.translate = false;
        this.finished = false;

        this.sub = null;
        this.queue = [];

        // Speakers
        this.num_speakers = 2;
        this.speakers = [];
        this.embeddings = [];
        this.updateSpeakersTimeout = null;

        // GPU
        this.gpuSession = null;
        this.isGPUModel = false;

        // Start and end
        this.start = null;
        this.end = null;

        // Module initialization
        window.Module.print = this.printAndCheck.bind(this);
        window.Module.printErr = this.printAndCheck.bind(this);
        window.Module.setStatus = function(text) {
            console.log('js:', text);
        }
        window.Module.monitorRunDependencies = function(left) {
        }

        // Miscelaneous
        this.timeoutId = null;

        // Youtube
        this.youtubeLink = null;

        // Printing
        this.linesCallback = null;
        this.audioPartsCallback = null;
        this.fullAudioCallback = null;
        this.changeState = null;
    }

    async printAndCheck(str, last=false, already_offsetted=false, premium=false, premium_i=0, speaker=null) {
        if (str === '') return;
        if (this.finished) return;

        const finish_commands = [
            'whisper_print_timings:',
        ]

        const debug_commands = [
            'whisper_init_from_file_no_state:',
            'whisper_model_load:',
            'whisper_init_state:',
            'system_info:',
            'operator():'
        ]

        if (debug_commands.some(cmd => str.includes(cmd))) {
            console.debug(str);
            return;
        }

        if (last || finish_commands.some(cmd => str.includes(cmd))) {
            console.log(str);

            if (this.timeoutId) clearTimeout(this.timeoutId);

            if (!premium) {
                this.timeoutId = setTimeout(() => {
                    this.processNextAudio(premium);
                    this.addOffset();
                }, 1000);
            }

            return;
        }

        this.changeState(6); // Transcripció en curs...

        // Adjust the subtitle timings
        const adjustedStr = this.adjustSubtitles(str, already_offsetted ? 0 : this.audioOffset + this.start, speaker);
        this.linesCallback(adjustedStr);
    }

    adjustSubtitles(str, offset, speaker=null) {
        function convertToMs(timestamp) {
            const parts = timestamp.split(':');
            const hours = parseInt(parts[0]);
            const minutes = parseInt(parts[1]);
            const seconds = parseFloat(parts[2]);
            return Math.round((hours * 3600 + minutes * 60 + seconds) * 1000);
        }
    
        function convertToTimestamp(milliseconds) {
            const hours = Math.floor(milliseconds / 3600000);
            const minutes = Math.floor((milliseconds % 3600000) / 60000);
            const seconds = Math.floor((milliseconds % 60000) / 1000);
            const ms = Math.floor(milliseconds % 1000);
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`;
        }

        const offsetInSeconds = offset * 1000; // Convert offset to milliseconds
        return str.replace(/\[([\d:.]+) --> ([\d:.]+)\]/g, (match, start, end) => {
            // Convert timestamps to milliseconds, add offset, and convert back to timestamp format
            const adjustedStart = convertToTimestamp(convertToMs(start) + offsetInSeconds);
            const adjustedEnd = convertToTimestamp(convertToMs(end) + offsetInSeconds);
            return `[${adjustedStart} --> ${adjustedEnd}] ${speaker !== null ? `(${speaker})` : ''}`;
        });
    }

    createTimestampSubtitles(start, end, offset) {
        function convertSToMs(seconds) {
            return Math.round(seconds * 1000);
        }

        function convertToTimestamp(milliseconds) {
            const hours = Math.floor(milliseconds / 3600000);
            const minutes = Math.floor((milliseconds % 3600000) / 60000);
            const seconds = Math.floor((milliseconds % 60000) / 1000);
            const ms = Math.floor(milliseconds % 1000);
            return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${ms.toString().padStart(3, '0')}`;
        }

        const offsetInSeconds = offset * 1000; // Convert offset to milliseconds

        const adjustedStart = convertToTimestamp(convertSToMs(start) + offsetInSeconds);
        const adjustedEnd = convertToTimestamp(convertSToMs(end) + offsetInSeconds);

        return `[${adjustedStart} --> ${adjustedEnd}]`;
    }

    async kill() {
        // this?.gpuSession?.destroy();
        window?.Module["PThread"]?.terminateAllThreads();
        this.finished = true;
    }

    async loadAudio() {
        return new Promise(async (resolve, reject) => {
            localforage.getItem('file').then(file => {
                const reader = new FileReader();
                reader.onload = async (event) => {
                    const preAudioData = reader.result;

                    const audioData = 
                        file.type === 'audio/wav' ? await convertToWav(preAudioData, 'wav', this.start, this.end) :
                        file.type === 'audio/mpeg' ? await convertToWav(preAudioData, 'mp3', this.start, this.end) :
                        file.type === 'audio/ogg' ? await convertToWav(preAudioData, 'ogg', this.start, this.end) :
                        file.type === 'audio/flac' ? await convertToWav(preAudioData, 'flac', this.start, this.end) :
                        file.type === 'audio/aac' ? await convertToWav(preAudioData, 'aac', this.start, this.end) :
                        file.type === 'audio/x-m4a' ? await convertToWav(preAudioData, 'm4a', this.start, this.end) :
                        await convertToWav(preAudioData, 'unknown', this.start, this.end);

                    await saveAudioToIndexedDB(audioData);

                    const asDataURL = await dataURLfromArrayBuffer(audioData);
                    this.showFullAudio(asDataURL);

                    resolve(true);            
                };
            
                reader.readAsArrayBuffer(file);
            }).catch(err => {
                // console.error('Error loading audio from IndexedDB', err);
                reject(err);
            });
        });
    }

    async loadAudioChunk() {
        return new Promise((resolve, reject) => {
            readNextChunkFromIndexedDB()
                .then(async ([chunkAudio, bufferData, chunkData]) => {
                    if (!chunkData) {
                        this.changeState(7); // Transcripció finalitzada
                        resolve(false);
                    }

                    this.audio = chunkAudio;
                    this.bufferData = bufferData;
                    this.chunkData = chunkData;
                    resolve(true);
                })
                .catch(err => {
                    // console.error('Error reading chunk from IndexedDB', err);
                    reject(err);
                });
        });
    }

    async storeModel(buf, fname='whisper.bin') {
        try {
            window.Module.FS_unlink(fname);
        } catch (e) {
            // ignore
        }

        window.Module.FS_createDataFile("/", fname, buf, true, true);
    }

    async loadInstance() {
        this.instance = window.Module.init('whisper.bin');
    }

    async showFullAudio(dataURL) {
        this.fullAudioCallback(dataURL);
    }

    async showAudioPart() {
        const reader = new FileReader();
        reader.onload = async (event) => {
            this.audioPartsCallback(reader.result);
        };

        reader.readAsDataURL(this.audio);
    }

    async addOffset() {
        const reader = new FileReader();
        reader.onload = async (event) => {
            const audioDataUrl = reader.result;
    
            // Create a new Audio object
            const audio = new Audio(audioDataUrl);
    
            // Once the audio is loaded, get its duration
            audio.onloadedmetadata = () => {
                const audioLengthInSeconds = audio.duration;
                this.audioOffset += audioLengthInSeconds;
            };
        };
    
        reader.readAsDataURL(this.audio);
    }

    async processFullAudio() {
        if (this.finished) return;
        if (!this.gpuSession) return;

        setTimeout(() => this.changeState(5), 1000); // Àudio processat. Comença la transcripció...

        localforage.getItem('audio')
            .then(async fullAudio => {
                const audioData = new Uint8Array(fullAudio);

                let builder = new DecodingOptionsBuilder();
                builder = builder.setLanguage(this.language);
                builder = builder.setBestOf(5);
                builder = builder.setBeamSize(5);
                builder = builder.setSuppressTokens(Int32Array.from([]));
                builder = builder.setSuppressBlank(false);
                builder = builder.setTask(Task.Transcribe);
                const options = builder.build();

                try {
                    await this.gpuSession.stream(
                        audioData,
                        false,
                        options,
                        (s) => {
                            const timestamp = this.createTimestampSubtitles(s.start, s.stop, this.audioOffset + this.start);
                            this.printAndCheck(timestamp + s.text, s.last, true, true);

                            if (s.last) {
                                this.changeState(7); // Transcripció finalitzada
                                this.kill();
                            }
                        }
                    )
                } catch (err) {
                    this.changeState(9); // Error en la transcripció
                    this.kill();
                }
            });
    }

    async printPremium(chunks) {
        chunks.forEach((chunk, i) => {
            const [start, stop] = chunk.timestamp;
            const timestamp = this.createTimestampSubtitles(start, stop, 0);
            this.printAndCheck(timestamp + chunk.text, false, false, true, i, chunk.speaker);
        });

        // Next audio
        this.processNextAudio(true);
        this.addOffset();
    }

    getNumSpeakers() {
        return this.num_speakers;
    }

    async updateSpeakers(new_embeddings=null) {
        this.emit('updateSpeakersStart');

        // Add new embeddings to the global array
        if (new_embeddings) this.embeddings.push(...new_embeddings);

        // Sanity checks
        if (this.embeddings.length <= 0 || this.num_speakers < 2 || this.embeddings.length < this.num_speakers) {
            this.emit('updateSpeakersEnd');
            return;
        }

        console.log('Updating speakers...', new_embeddings?.length, this.embeddings?.length, this.num_speakers);

        fetch('/get-speakers-from-embeddings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                embeddings: this.embeddings,
                num_speakers: this.num_speakers,
            }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Updated speakers:', data);
                this.speakers = data;
                this.speakersCallback(this.speakers);
            })
            .catch(error => {
                console.error('Error updating speakers:', error);
            })
            .finally(() => {
                this.emit('updateSpeakersEnd');
            });
    }

    async processPremiumAudio() {
        const formData = new FormData();
        formData.append('audio', this.audio);
        formData.append('sub', this.sub);

        fetch('/upload-audio', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data?.fileName) {
                    const fileName = data?.fileName;

                    const attemptFetch = (attemptsLeft, callback, errorCallback) => {
                        if (attemptsLeft <= 0) {
                            console.error('Error transcribing audio after multiple attempts...');
                            errorCallback();
                            return;
                        }

                        fetch('/transcribe-audio', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                fileName: fileName,
                                language: languageMap[this.language],
                            }),
                        })
                            .then(response => response.json())
                            .then(data => {
                                console.log('Transcription result:', data);
                                callback(data);

                                // Get the embeddings, and then update the speakers
                                this.emit('createEmbeddingsStart');

                                fetch('/get-embeddings-from-whisper', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({ fileName, output: data }),
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        this.emit('createEmbeddingsEnd');
                                        console.log('Embeddings result:', data);
                                        this.updateSpeakers(data.chunks.map(chunk => chunk.embedding));
                                    })
                                    .catch(error => {
                                        console.error('Error getting embeddings:', error);
                                        this.emit('createEmbeddingsEnd');
                                    });
                            })
                            .catch(error => {
                                if (attemptsLeft > 0) {
                                    console.error('Attempt failed, retrying...', error);
                                    attemptFetch(attemptsLeft - 1, callback, errorCallback);
                                } else {
                                    console.error('Error transcribing audio after multiple attempts:', error);
                                }
                            });
                    };

                    attemptFetch(
                        3,
                        (data) => {
                            this.printPremium(data.chunks);
                        },
                        (error) => {
                            this.changeState(9); // Error en la transcripció
                            this.kill();
                        }
                    )
                }
            });
    }

    async processNextAudio(premium=false) {
        if (this.finished) return;
        setTimeout(() => this.changeState(5), 1000); // Àudio processat. Comença la transcripció...

        if (!premium && !this.instance) this.loadInstance();

        const audioFound = await this.loadAudioChunk()
        if (!audioFound) {
            this.changeState(7); // Transcripció finalitzada
            return;
        }

        this.showAudioPart();

        if (premium) {
            this.processPremiumAudio();
        } else {
            const result = window.Module.full_default(
                this.instance, 
                this.chunkData, 
                this.language, 
                this.nthreads,
                this.translate,
            )
        }
    }

    async process() {
        this.changeState(4); // Processant àudio...

        // Check if the YouTube link is set
        if (this.youtubeLink) {
            try {
                await this.processYoutubeLink(this.youtubeLink);

                // Load audio after
                this.loadAudio()
                    .then(() => {
                        if (this.isGPUModel) this.processFullAudio();
                        else this.processNextAudio();
                    })
                    .catch(err => {
                        if (this.isGPUModel) this.processFullAudio();
                        else this.processNextAudio();
                    });
            } catch (err) {
                console.error('Error processing YouTube link:', err);
                this.changeState(9); // Error en la transcripció
                this.kill();
                return;
            }
        } else {
            this.loadAudio()
                .then(() => {
                    if (this.isGPUModel) this.processFullAudio();
                    else this.processNextAudio();
                })
                .catch(err => {
                    if (this.isGPUModel) this.processFullAudio();
                    else this.processNextAudio();
                });
        }
    }

    setYoutubeLink(link) {
        this.youtubeLink = link;
    }

    processYoutubeLink(link, sub=null) {
        return new Promise(async (resolve, reject) => {
            try {
                removeFileFromIndexedDB();

                const response = await fetch('/upload-youtube-link', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ url: link, sub: sub || 'proves-ui' }),
                });
                const data = await response.json();
                if (response.ok) {
                    fetch(data.audioFileURL)
                        .then(res => res.blob())
                        .then(blob => {
                            localforage.setItem('file', blob)
                                .then(data => {
                                    console.log('Saved audio file to IndexedDB:', blob);
                                    resolve();
                                })
                                .catch(err => {
                                    console.error('Error saving audio file to IndexedDB:', blob);
                                    reject(err);
                                });
                        });
                } else {
                    console.error('Error uploading YouTube link:', data);
                    reject(data);
                }
            } catch (err) {
                console.error('Error uploading YouTube link:', err);
                reject(err);
            }
        });
    }

    async processPremium() {
        this.changeState(4); // Processant àudio...

        // Check if the YouTube link is set
        if (this.youtubeLink) {
            try {
                await this.processYoutubeLink(this.youtubeLink);

                // Load audio after
                this.loadAudio()
                    .then(() => {
                        this.processNextAudio(true);
                    })
                    .catch(err => {
                        this.processNextAudio(true);
                    });
            } catch (err) {
                console.error('Error processing YouTube link:', err);
                this.changeState(9); // Error en la transcripció
                this.kill();
                return;
            }
        } else {
            // Load audio after
            this.loadAudio()
                .then(() => {
                    this.processNextAudio(true);
                })
                .catch(err => {
                    this.processNextAudio(true);
                });
        }
    }

    setNumSpeakers(num_speakers) {
        this.num_speakers = parseInt(num_speakers);

        if (this.updateSpeakersTimeout) clearTimeout(this.updateSpeakersTimeout);

        this.updateSpeakersTimeout = setTimeout(() => {
            this.updateSpeakers();
        }, 2000);
    }

    async setModel(modelName, model) {
        this.isGPUModel = modelName.toLowerCase().includes('gpu');

        if (this.isGPUModel) {
            const TOKENIZER = await fetchTokenizer('gpu');

            await whisper.default();
            const builder = new whisper.SessionBuilder();
            const session = await builder
                .setModel(model)
                .setTokenizer(TOKENIZER)
                .build()
            
            this.gpuSession = session;
            await initialize();
        } else {
            await this.storeModel(model);
        }
    }

    setLanguage(language) {
        this.language = language;
    }

    setOutput({ lines, audioParts, fullAudio, speakers, changeState }) {
        this.linesCallback = lines;
        this.audioPartsCallback = audioParts;
        this.fullAudioCallback = fullAudio;
        this.speakersCallback = speakers;
        this.changeState = changeState;
    }

    setStart(start) {
        this.start = start;
    }

    setEnd(end) {
        this.end = end;
    }

    setSub(sub) {
        this.sub = sub;
    }
}