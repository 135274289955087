import React, { useState, useEffect } from 'react';
import { WASMProcessor } from './utils/WASMProcessor';
import FileUploader from './components/FileUploader';
import ModelLoader from './components/ModelLoader';
import Processor from './components/Processor';
import Output from './components/Output';
import Header from './components/extra/Header';
import LanguageSelector from './components/model/LanguageSelector';
import './styles/common.css';
import SideBySide from './components/extra/SideBySide';
import Credits from './components/extra/Credits';
import FeedbackForm from './components/extra/FeedbackForm';
import PremiumFeatures from './components/PremiumFeatures';
import NumSpeakersChooser from './components/NumSpeakersChooser';

const AudioProcessor = () => {
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [isAudioLoaded, setIsAudioLoaded] = useState(false);
    const [processor, setProcessor] = useState(null);
    const [state, setState] = useState(0);
    const [fileName, setFileName] = useState(null);
    const [isPremium, setIsPremium] = useState(false);
    const [userData, setUserData] = useState(null);
    const [s3Filename, setS3Filename] = useState(null);
    const [showPremiumPopup, setShowPremiumPopup] = useState(false);
    const [numSpeakers, setNumSpeakers] = useState(1);

    useEffect(() => {
        setProcessor(new WASMProcessor());
    }, []);

    useEffect(() => {
        if (isPremium) {
            setIsModelLoaded(true);
        }
    }, [isPremium]);

    return (
        <div>
            <PremiumFeatures showPremiumPopup={showPremiumPopup} setShowPremiumPopup={setShowPremiumPopup} userData={userData} setUserData={setUserData} isPremium={isPremium} setIsPremium={setIsPremium} />

            <Header isPremium={isPremium} />

            <ModelLoader showPremiumPopup={showPremiumPopup} setShowPremiumPopup={setShowPremiumPopup} isPremium={isPremium} state={state} setState={setState} processor={processor} success={() => setIsModelLoaded(true)} error={(err) => console.error(err)} numSpeakers={numSpeakers} setNumSpeakers={setNumSpeakers} />

            {
                state <= 3 && <>
                    <FileUploader setS3Filename={setS3Filename} isPremium={isPremium} setFileName={setFileName} isAudioLoaded={isAudioLoaded} state={state} processor={processor} success={() => setIsAudioLoaded(true)} error={(err) => console.error(err)} />
                    
                    <SideBySide gap={10}>
                        <LanguageSelector state={state}  processor={processor} />
                        { isPremium && <NumSpeakersChooser processor={processor} numSpeakers={numSpeakers} setNumSpeakers={setNumSpeakers} /> }
                        <Processor s3Filename={s3Filename} isPremium={isPremium} state={state} processor={processor} />
                    </SideBySide>
                </>
            }

            <Output fileName={fileName} state={state} setState={setState} processor={processor} isModelLoaded={isModelLoaded} isAudioLoaded={isAudioLoaded} numSpeakers={numSpeakers} isPremium={isPremium} />
            
            <FeedbackForm isPremium={isPremium} />
            <Credits />
        </div>
    );
}

export default AudioProcessor;