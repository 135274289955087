import React, { forwardRef } from 'react';

const FullAudio = forwardRef(({ fullAudio }, ref) => {
    return (
        <div
            style={{
                marginTop: '10px',
                marginBottom: '10px',
                width: '100%',
            }}
        >
            {fullAudio && <audio
                controls
                src={fullAudio}
                ref={ref}
                style={{
                    width: '100%',
                }}
            />}
        </div>
    )
});

export default FullAudio;