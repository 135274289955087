import React, { useState } from 'react';

const Processor = ({ processor, state, isPremium, s3Filename, language }) => {
    const isReady = state === 3;

    const handleClick = () => {
        if (isReady) {
            processor?.process();
        }
    };

    const transcribePremium = () => {
        if (isPremium && processor) {
            processor?.processPremium();
        }
    };

    return (<>
        <button className={isPremium ? 'premium' : ''} onClick={isPremium ? transcribePremium : handleClick} disabled={isPremium ? (state < 2) : !isReady}>
            Transcriu
        </button>
    </>);
};

export default Processor;
